import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.loadBootstrapTableLibraries()
            .then(() => {
                if (typeof window.initializeCustomBootstrapTable === 'function') {
                    window.initializeCustomBootstrapTable();
                }
            })
            .catch((error) => {
                console.error("Error loading scripts:", error);
            });
    }

    loadBootstrapTableLibraries() {
        // Load Bootstrap Table and its extensions
        return this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap-table@1.22.6/dist/bootstrap-table.min.js')
            .then(() => this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap-table@1.22.6/dist/bootstrap-table-locale-all.min.js'))
            .then(() => this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-table/1.22.6/extensions/filter-control/bootstrap-table-filter-control.min.js'));
    }

    loadScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }
}
