import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    // Bind the initializePopovers method to preserve the context when removing listeners
    this.boundInitializePopovers = this.initializePopovers.bind(this);

    this.initializePopovers(); // Initial call when controller is connected

    // Add event listeners for popover initialization on custom events
    document.addEventListener('turbo:frame-load', this.boundInitializePopovers);
    document.addEventListener('simulation:updated', this.boundInitializePopovers);
  }

  disconnect() {
    // Remove the event listeners when the controller is disconnected
    document.removeEventListener('turbo:frame-load', this.boundInitializePopovers);
    document.removeEventListener('simulation:updated', this.boundInitializePopovers);
  }

  initializePopovers() {
    // Find all elements with data-bs-toggle="popover" and initialize popovers
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

    popoverTriggerList.map(function (popoverTriggerEl) {
      new bootstrap.Popover(popoverTriggerEl, {
        trigger: 'hover focus',
        html: true,
        placement: 'bottom'
      });
    });
  }
}
